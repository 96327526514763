import {
  CurrencyDollarIcon,
  FlagIcon,
  MapPinIcon,
  CalendarDaysIcon,
} from "@heroicons/react/24/solid";
import classNames from "classnames";
import { Container } from "components/layout";
import { truncate } from "lodash";
import { Link } from "react-router-dom";
import { Event } from "types/Event";

export const EventHeader = ({
  event,
  showRefund,
  showFullDescription,
}: {
  showFullDescription: () => void;
  event: Event;
  showRefund?: () => void;
}) => {
  const primaryVenue = event?.primary_venue;

  const AddressLink = ({ address }: { address: string }) => {
    const encodedAddress = encodeURIComponent(address);
    const googleMapsUrl = `https://www.google.com/maps/search/?api=1&query=${encodedAddress}`;

    return (
      <a href={googleMapsUrl} target="_blank" rel="noopener noreferrer">
        {address}
      </a>
    );
  };
  const youtubeUrlID = event.youtube_url?.split("watch?v=")[1];

  return (
    <Container className="py-5 xl:py-12 lg:py-10">
      <div className="w-full flex flex-col sm:flex-row items-center gap-4 md:gap-10">
        <div
          className={`
            w-44 h-44 sm:w-60 sm:h-60 lg:w-80 lg:h-80 rounded-full p-1 sm:p-2 relative overflow-hidden 
            ${
              event.organization.primary_color
                ? ""
                : "bg-gradient-to-tr from-pink to-purple"
            }
          `}
          style={{
            background: event.organization.primary_color
              ? event.organization.secondary_color
                ? `linear-gradient(to top right, ${event.organization.primary_color}, ${event.organization.secondary_color})`
                : event.organization.primary_color
              : undefined,
          }}
        >
          {youtubeUrlID ? (
            <iframe
              className="absolute top-0 left-0 w-full h-full object-cover z-0 pointer-events-none"
              src={`https://www.youtube.com/embed/${youtubeUrlID}?autoplay=1&mute=1&controls=0&loop=1&playlist=${youtubeUrlID}&modestbranding=1&showinfo=0&rel=0`}
              title="YouTube video background"
              allow="autoplay; fullscreen"
              allowFullScreen
            />
          ) : event.image_url ? (
            <img
              src={event.image_url}
              className="w-full h-full object-cover rounded-full"
            />
          ) : (
            <img
              src="//lvgweb.s3.us-east-2.amazonaws.com/happy/happie-guy-white.svg"
              className="w-full h-full object-cover rounded-full p-5"
            />
          )}
        </div>
        <div className="w-full items-center sm:w-2/3 flex flex-col gap-2 sm:gap-10 justify-between h-full">
          <div className="w-full">
            <span className="block text-2xl text-center sm:text-left font-extrabold md:text-3xl lg:text-5xl">
              {event.name}
            </span>
            {event.description?.length > 350 ? (
              <p className="my-2 sm:text-lg text-center sm:text-left">
                {truncate(event.description, { length: 350 })}
                <span
                  onClick={showFullDescription}
                  className={classNames(
                    "font-semibold text-sm",
                    event.organization.primary_color
                      ? `text-${event.organization.primary_color}`
                      : "text-pink"
                  )}
                  role="button"
                >
                  Read more
                </span>
              </p>
            ) : (
              <p className="mt-2 sm:text-lg text-center sm:text-left">
                {event.description}
              </p>
            )}
          </div>
          {}
          <div className="w-full flex flex-col gap-1">
            {event.organization && (
              <span className=" sm:justify-start sm:text-left text-xs sm:text-base flex items-center gap-2">
                <FlagIcon className="h-4 w-4 inline-block" />
                <span>
                  Presented By:{" "}
                  <Link
                    className="font-bold hover:brightness-110"
                    to={`/orgs/${event.organization.id}`}
                    style={{
                      color: event.organization.secondary_color
                        ? event.organization.secondary_color
                        : "#7208B7",
                    }}
                  >
                    {event.organization.name}
                  </Link>
                </span>
              </span>
            )}
            <span className=" sm:justify-start text-xs sm:text-base flex items-center gap-2">
              <MapPinIcon className="h-4 w-4 inline-block" />
              {primaryVenue?.name} -
              <span
                className="font-bold"
                style={{
                  color: event.organization.secondary_color
                    ? event.organization.secondary_color
                    : "#7208B7",
                }}
              >
                <AddressLink
                  address={`${event.primary_venue.address_1}, ${event.primary_venue.city}, ${event.primary_venue.state}`}
                />
              </span>
            </span>
            <span className=" sm:justify-start text-xs sm:text-base flex items-center gap-2">
              <CalendarDaysIcon className="h-4 w-4 inline-block" />
              <span>{event.start}</span>
            </span>

            <span className=" sm:justify-start text-xs sm:text-base flex items-center gap-2">
              <span className="">
                <CurrencyDollarIcon className="h-4 w-4 mr-1 mb-1 inline-block" />{" "}
                <button
                  className=" font-bold hover:brightness-110"
                  style={{
                    color: event.organization.secondary_color
                      ? event.organization.secondary_color
                      : "#7208B7",
                  }}
                  onClick={showRefund}
                >
                  Refund Policy
                </button>
              </span>
            </span>
          </div>
        </div>
      </div>
    </Container>
  );
};
