import { Tab, TabGroup, TabList, TabPanel, TabPanels } from "@headlessui/react";
import { Container } from "components/layout";
import { motion } from "framer-motion";
import { useEffect, useState } from "react";
import classNames from "classnames";
import { LandingNav } from "pages/Landing/Nav";
import Footer from "components/navigation/Footer";
import { Button } from "components/buttons";
import { Link } from "react-router-dom";
import { useInterval } from "components/hooks/useInterval";
import { Link as ScrollLink } from "react-scroll";
import { MarketingVideo } from "components/MarketingVideo";
import { Helmet } from "react-helmet";
import {
  CalendarIcon,
  ChartPieIcon,
  CurrencyDollarIcon,
  QrCodeIcon,
  TicketIcon,
} from "@heroicons/react/24/solid";
const isMobile = window.innerWidth < 640;
const features = [
  {
    title: "Ticket Sales",
    description:
      "Create and sell tickets hassle-free from your dedicated organization and event pages.",
    image: "//lvgweb.s3.us-east-2.amazonaws.com/happy/screenshots/events.png",
  },
  {
    title: "POS",
    description:
      "Scan cards, accept cash, and track sales instantly. We make it easy to sell tickets before the event, at the door, or on the go.",
    image: "//lvgweb.s3.us-east-2.amazonaws.com/happy/screenshots/pos.png",
  },
  {
    title: "Event Management",
    description:
      "Speed up entry with our scanning app. Event staff can scan tickets, confirm ticket details, and manage entry with ease.",
    image:
      "//lvgweb.s3.us-east-2.amazonaws.com/happy/screenshots/eventmgmt.png",
  },
  {
    title: "Analytics",
    description:
      "Access real-time data with our convenient dashboard and reports. Track sales, monitor attendance, and make data-driven decisions.",

    image:
      "//lvgweb.s3.us-east-2.amazonaws.com/happy/screenshots/analytics.png",
  },
];

export function Selling() {
  let [tabOrientation, setTabOrientation] = useState<"horizontal" | "vertical">(
    "horizontal"
  );
  const [prodIndex, setProdIndex] = useState(0);

  const prods = [
    "Sporting Event",
    "Concert",
    "Parking Lot",
    "Pottery Class",
    "Festival",
    "Dinner Party",
    "Charity Gala",
  ];

  useInterval({
    callback: () => {
      // Your custom logic here
      if (prodIndex >= prods.length - 1) {
        return setProdIndex(0);
      }
      setProdIndex(prodIndex + 1);
    },
    delay: 3000,
  });

  useEffect(() => {
    let lgMediaQuery = window.matchMedia("(min-width: 1024px)");

    function onMediaQueryChange({ matches }: { matches: boolean }) {
      setTabOrientation(matches ? "vertical" : "horizontal");
    }

    onMediaQueryChange(lgMediaQuery);
    lgMediaQuery.addEventListener("change", onMediaQueryChange);

    return () => {
      lgMediaQuery.removeEventListener("change", onMediaQueryChange);
    };
  }, []);

  return (
    <>
      <Helmet>
        <title>Happy Ticketing</title>
        <meta
          name="description"
          content="Happy Ticketing is a free, all-in-one platform designed to simplify selling tickets and facilitating entry to any experience."
        />
      </Helmet>
      <LandingNav />
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
      >
        <section className="py-10 relative z-10 overflow-hidden flex flex-col justify-center mt-[76px] items-center sm:py-24 xl:px-60 px-6">
          <img
            alt={"feature bg"}
            className="-z-10 absolute top-0 bottom-0 left-0 right-0 m-auto"
            src="//lvgweb.s3.us-east-2.amazonaws.com/happy/poly-bg-corners.jpg"
          />
          {/* <h1 className="max-w-4xl text-3xl sm:text-4xl md:text-5xl lg:text-7xl text-gray-600 text-center font-bold">
            Seamless Ticketing,{" "}
            <span className="text-aqua">Happy Experience. </span>
          </h1> */}
          <div>
            <MarketingVideo width={isMobile ? 300 : 600} />
          </div>
          <p className="mx-auto mt-6 max-w-2xl text-lg tracking-tight text-slate-700 text-center">
            Discover Happy Ticketing — a FREE, all-in-one platform designed to
            simplify selling tickets and facilitating entry to any experience.
          </p>
          <div className="flex flex-col gap-10 items-center">
            <Link data-testid={"vendor-get-started-button"} to="/get-started">
              <Button
                variant="pink"
                className="shadow-md mt-12 px-16 py-6 lg:px-32 lg:py-10 lg:text-2xl rounded-xl"
              >
                Get Started
              </Button>
            </Link>

            <Button variant="link">
              <ScrollLink smooth={true} to="features">
                Learn More
              </ScrollLink>
            </Button>
          </div>
        </section>

        <section
          id="features"
          aria-label="Features for Happy Ticketing"
          className="relative overflow-hidden bg-blue pb-28 pt-20 sm:py-32"
        >
          <img
            className="absolute left-1/2 top-1/2 max-w-none translate-x-[-44%] translate-y-[-42%]"
            src={
              "//lvgweb.s3.us-east-2.amazonaws.com/happy/happy-features-bg-4k.jpg"
            }
            alt=""
            width={2245}
            height={1636}
          />
          <Container className="relative">
            <div className="max-w-2xl md:mx-auto md:text-center xl:max-w-none">
              <h2 className="text-3xl font-milkshake text-white sm:text-4xl md:text-5xl">
                Everything You Need To Sell Tickets
              </h2>
              <p className="mt-6 text-lg tracking-tight text-white">
                Our comprehensive suite of tools offers the flexibility to
                market, sell, scan, and collect ticket revenue for all your
                organization's events.
              </p>
            </div>
            <TabGroup
              as="div"
              className="mt-16 grid grid-cols-1 items-center gap-y-2 pt-10 sm:gap-y-6 md:mt-20 lg:grid-cols-12 lg:pt-0"
              vertical={tabOrientation === "vertical"}
            >
              {({ selectedIndex }) => (
                <>
                  <div className="-mx-4 flex overflow-x-auto pb-4 sm:mx-0 sm:overflow-visible sm:pb-0 lg:col-span-5">
                    <TabList className="relative z-10 flex gap-x-4 whitespace-nowrap px-4 sm:mx-auto sm:px-0 lg:mx-0 lg:block lg:gap-x-0 lg:gap-y-1 lg:whitespace-normal">
                      {features.map((feature, featureIndex) => (
                        <div
                          key={feature.title}
                          className={classNames(
                            "group relative rounded-full px-4 py-1 lg:rounded-l-xl lg:rounded-r-none lg:p-6",
                            selectedIndex === featureIndex
                              ? "bg-white lg:bg-white/10 lg:ring-1 lg:ring-inset lg:ring-white/10"
                              : "hover:bg-white/10 lg:hover:bg-white/5"
                          )}
                        >
                          <h3>
                            <Tab
                              className={classNames(
                                "font-display text-lg outline-none",
                                selectedIndex === featureIndex
                                  ? "text-blue lg:text-white"
                                  : "text-white hover:text-white lg:text-white"
                              )}
                            >
                              <span className="absolute inset-0 rounded-full lg:rounded-l-xl lg:rounded-r-none" />
                              {feature.title}
                            </Tab>
                          </h3>
                          <p
                            className={classNames(
                              "mt-2 hidden text-sm lg:block",
                              selectedIndex === featureIndex
                                ? "text-white"
                                : "text-white opacity-80 group-hover:opacity-100"
                            )}
                          >
                            {feature.description}
                          </p>
                        </div>
                      ))}
                    </TabList>
                  </div>
                  <TabPanels className="lg:col-span-7">
                    {features.map((feature) => (
                      <TabPanel key={feature.title} unmount={false}>
                        <div className="relative sm:px-6 lg:hidden">
                          <div className="absolute -inset-x-4 bottom-[-4.25rem] top-[-6.5rem] bg-white/10 ring-1 ring-inset ring-white/10 sm:inset-x-0 sm:rounded-t-xl" />
                          <p className="relative mx-auto max-w-2xl text-base text-white sm:text-center">
                            {feature.description}
                          </p>
                        </div>
                        <div className="mt-10 w-[45rem] overflow-hidden rounded-xl bg-slate-50 shadow-xl shadow-blue-900/20 sm:w-auto lg:mt-0 lg:w-[67.8125rem]">
                          <img
                            className="w-full"
                            src={feature.image}
                            alt=""
                            sizes="(min-width: 1024px) 67.8125rem, (min-width: 640px) 100vw, 45rem"
                          />
                        </div>
                      </TabPanel>
                    ))}
                  </TabPanels>
                </>
              )}
            </TabGroup>
          </Container>
        </section>
        <section id="how-it-works" className="py-16 w-full relative">
         
          <h2 className="text-3xl font-milkshake text-center sm:text-4xl md:text-5xl">
            How It Works
          </h2>

          <div className="flex flex-col items-center gap-10 sm:gap-0 sm:items-start sm:flex-row justify-around w-full py-10 max-w-7xl mx-auto">
            <div className="w-36 flex flex-col items-center">
              <img
                src="/calendar_icon.png"
                alt="calendar_icon"
                className="h-32 w-32"
              />
              <h4 className="text-center text-xl mt-2">Create Your Event</h4>
            </div>
            <div className="w-36 flex flex-col items-center">
              <img
                src="/tickets_icon.png"
                alt="tickets_icon"
                className="h-32 w-32"
              />
              <h4 className="text-center text-xl mt-2">Sell Tickets</h4>
            </div>

            <div className="w-36 flex flex-col items-center">
              <img
                src="/track_sales_icon.png"
                alt="track_sales_icon"
                className="h-32 w-32"
              />
              <h4 className="text-center text-xl mt-2">
                Track Sales and Attendance
              </h4>
            </div>

            <div className="w-36 flex flex-col items-center">
              <img
                src="/qr_code_icon.png"
                alt="qr_code_icon"
                className="h-32 w-32"
              />
              <h4 className="text-center text-xl mt-2">Scan Tickets at Entry</h4>
            </div>

            <div className="w-36 flex flex-col items-center">
              <img
                src="/dollar_icon.png"
                alt="dollar_icon"
                className="h-32 w-32"
              />
              <h4 className="text-center text-xl mt-2">Get Paid</h4>
            </div>
          </div>
        </section>
        <section
          style={{
            backgroundImage: `url(//lvgweb.s3.us-east-2.amazonaws.com/happy/happy-purp.png)`,
            backgroundSize: "cover",
          }}
          className="w-full flex items-center justify-center text-white py-10 sm:py-20"
        >
          <Container>
            <div className="w-full flex flex-col items-center gap-10">
              <div>
                <h2 className="text-center font-milkshake text-3xl text-white sm:text-4xl md:text-5xl">
                  Tickets Anytime. Anywhere.
                </h2>
                <p className="mt-4 text-xl text-white/70 text-center">
                  From concerts to art exhibits, parking lots to dinner parties,
                  and everything in between — we ensure seamless entry to any
                  experience
                </p>

                <div className="flex justify-between flex-wrap mt-10">
                  <div className="w-full md:w-1/2 text-center px-5">
                    <h2 className="text-white/70 text-3xl tracking-tight font-bold">
                      Zero Cost to You
                    </h2>
                    <p className="text-white/70 mt-6 text-lg tracking-tight">
                      As a vendor with Happy Ticketing, you enjoy the perks
                      without the price tag - ZERO fees. Instead, a clear fee
                      structure is applied to your customers at checkout: a
                      2.99% + $0.30 processing fee and a service charge of $1.50
                      per ticket - that's it. You and your customers will know
                      exactly what to expect, every single time. Transparent,
                      straightforward, and keeping everyone Happy – the way
                      ticketing should be.
                    </p>
                  </div>

                  <div className="w-full md:w-1/2 px-5 text-center mt-7 lg:mt-0">
                    <h2 className="text-white/70 text-3xl tracking-tight font-bold">
                      Effortless Setup
                    </h2>
                    <p className="text-white/70 mt-6 text-lg tracking-tight">
                      Our intuitive platform guides you through the event
                      creation and ticket selling process, ensuring your event
                      is live in no time. Stay on top of everything with
                      real-time reports and live data, giving you insights from
                      the moment tickets go on sale until the event concludes.
                      Our intuitive interface guides your customers through
                      seamless checkout, making ticket buying a breeze.
                      Hassle-free management for you, Maximum enjoyment for
                      them.
                    </p>
                  </div>
                </div>
              </div>
              <Link
                to="/get-started"
                className="focus:ring-1 focus:ring-white text-white bg-lightPurple px-10 py-6 shadow-lg rounded text-xl font-bold hover:-translate-y-1 transition-all hover:shadow-2xl hover:brightness-105 active:translate-y-0"
              >
                Start Selling Tickets
              </Link>
            </div>
          </Container>
        </section>
      </motion.div>
    </>
  );
}
