import { Tab, TabGroup, TabList, TabPanel, TabPanels } from "@headlessui/react";
import { LoginForm } from "components/forms/Auth";
import { GLogin } from "components/forms/GLogin";
import { OtpForm } from "./OtpForm";
import { useAuth } from "providers/AuthContext";
import { useState } from "react";
import { Button } from "components/buttons";
import { Link } from "react-router-dom";

export const Login = ({}: {}) => {
  const { login } = useAuth();
  const [loading, setLoading] = useState<boolean>(false);
  const handleSubmit = (values: { email: string; password: string }) => {
    setLoading(true);
    login(values);
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  };

  return (
    <div className="min-h-full flex items-center justify-center py-5 px-4 sm:px-6 lg:px-8">
      <div className="max-w-md w-full">
        <div>
          <div className="flex justify-center items-center">
            <img
              className="w-7 py-4"
              src="//lvgweb.s3.us-east-2.amazonaws.com/happy/happie-guy-colored.svg"
              alt="Happy Ticketing Company"
            />
            <div className="ml-2">
              <h2 className="text-xl font-milkshake text-purple">
                Happy Ticketing
              </h2>
            </div>
          </div>
        </div>
        <TabGroup>
          <TabList className="flex justify-center items-center my-6">
            <Tab
              className={
                "data-[selected]:bg-purple/10 rounded-lg px-5 py-2 data-[selected]:text-purple data-[selected]:font-bold"
              }
            >
              SMS
            </Tab>
            <Tab
              className={
                "data-[selected]:bg-purple/10 rounded-lg px-5 py-2 data-[selected]:text-purple data-[selected]:font-bold"
              }
            >
              Email
            </Tab>
          </TabList>
          <TabPanels>
            <TabPanel>
              <OtpForm />
              <div className="text-sm mt-10 text-center">
                <span>
                  Dont have an account yet?{" "}
                  <Link to="/register">
                    <Button variant="link">Create one now.</Button>
                  </Link>
                </span>
              </div>
            </TabPanel>
            <TabPanel>
              <h3 className="text-center text-lg font-extrabold">
                Log in to your account
              </h3>
              <LoginForm handleSubmit={handleSubmit} loading={loading} />
              <div className="hidden lg:block">
                <span className="block text-center border-b-2 border-gray-300 mb-10" />
                <GLogin tos={true} />
              </div>
            </TabPanel>
          </TabPanels>
        </TabGroup>
      </div>
    </div>
  );
};
