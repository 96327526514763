import { Navbar } from "components/navigation/Navbar";
import { Container } from "components/layout";
import { Navigate, Route, Routes, useLocation, useParams, useSearchParams } from "react-router-dom";
import { Name } from "./Name";
import { Email } from "./Email";
import { Org } from "./Org";
import { AnimatePresence } from "framer-motion";
import { Helmet } from "react-helmet";
import { useEffect } from "react";

export const GetStarted = ({}: {}) => {
  const location = useLocation();
  const [params] = useSearchParams();
  const partnerCode = params.get("partner_code");

  useEffect(() => {
    if (partnerCode) {
      localStorage.setItem("partner_code", partnerCode);
    }
  }, [partnerCode]);

  return (
    <>
      <Helmet>
        <title>Get Started with Happy</title>
        <meta
          name="description"
          content="Create a vendor account to start selling tickets on Happy."
        />
      </Helmet>
      <Navbar />
      <div
        className="min-h-screen w-screen"
      >
        <Container>
          <AnimatePresence mode="wait">
            <Routes location={location} key={location.pathname}>
              <Route path="/" element={<Name />} />
              <Route path="/email" element={<Email />} />
              <Route path="/finish" element={<Org />} />
              <Route path="*" element={<Navigate to="/get-started" />} />
            </Routes>
          </AnimatePresence>
        </Container>
        {partnerCode && (
          <span className="block mx-auto w-fit mt-5">
            Using Partner Code: <strong>{partnerCode}</strong>
          </span>
        )}
        {!location.pathname.includes("finish") && (
          <div className="text-center mt-10 text-sm">
            <p>
              Already have a Happy vendor account?{" "}
              <a
                className="text-pink font-bold"
                href={`${import.meta.env.VITE_ADMIN_WEB_URL}`}
              >
                Sign in here.
              </a>
            </p>
          </div>
        )}
      </div>
    </>
  );
};
