import {
  ArrowRightIcon,
  CurrencyDollarIcon,
  PencilIcon,
  ShareIcon,
} from "@heroicons/react/24/solid";
import { Button } from "components/buttons";
import { RegisterForm } from "components/forms/Auth";
import { Container } from "components/layout";
import { Navbar } from "components/navigation/Navbar";
import { useAuth } from "providers/AuthContext";
import { useState } from "react";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import { formatCurrency } from "utils/currency";
import { generateRandomString } from "utils/string";
import { PartnerRequestForm } from "./PartnerRequestForm";

export const HappyPartners = ({}: {}) => {
  const [showPartnerForm, setShowPartnerForm] = useState(false);
  const handleShowPartnerForm = () => {
    setShowPartnerForm(true);
    setTimeout(() => {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }, 100);
  }
  return (
    <>
      <Helmet>
        <title>Happy Partner Program</title>
        <meta
          name="description"
          content="Join our Partner Program and earn revenue for every ticket sold by vendors you bring to our platform."
        />
      </Helmet>
      <Navbar />
      {showPartnerForm ? (
        <NewPartner />
      ) : (
        <>
          <Hero handleShowPartnerForm={handleShowPartnerForm} />
          <Intro />
          <div className="bg-gradient-to-b from-lightPurple to-white pt-20 pb-60">
            <HowItWorks />
            <RevenueCalculator />
            <CTA handleShowPartnerForm={handleShowPartnerForm} />
          </div>
        </>
      )}
    </>
  );
};

export const Hero = ({
  handleShowPartnerForm,
}: {
  handleShowPartnerForm: () => void;
}) => {
  const goToIntro = () => {
    document.getElementById("intro")?.scrollIntoView({ behavior: "smooth" });
  };
  return (
    <div className="relative isolate px-6 pt-14 lg:px-8">
      <div
        aria-hidden="true"
        className="absolute inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-80"
      >
        <div
          style={{
            clipPath:
              "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
          }}
          className="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-purple to-blue opacity-30 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]"
        />
      </div>
      <div className="mx-auto max-w-2xl py-32 sm:py-48 lg:py-56">
        <div className="text-center">
          <h1 className="text-balance text-5xl font-semibold tracking-tight text-gray-900 sm:text-7xl">
            Grow with Us: Join Our Partner Program
          </h1>
          <p className="mt-8 text-pretty text-lg font-medium text-gray-500 sm:text-xl/8">
            Earn $0.10 for every ticket sold by vendors you bring to our
            platform. Start building a passive income today!
          </p>
          <div className="mt-10 flex items-center justify-center gap-x-6">
            <Button onClick={handleShowPartnerForm} variant="primary">
              Become a Partner
            </Button>
            <button
              onClick={goToIntro}
              className="text-sm/6 font-semibold text-gray-900"
            >
              Learn more <span aria-hidden="true">→</span>
            </button>
          </div>
        </div>
      </div>
      <div
        aria-hidden="true"
        className="absolute inset-x-0 top-[calc(100%-13rem)] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[calc(100%-30rem)]"
      >
        <div
          style={{
            clipPath:
              "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
          }}
          className="relative left-[calc(50%+3rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 bg-gradient-to-tr from-pink to-aqua opacity-30 sm:left-[calc(50%+36rem)] sm:w-[72.1875rem]"
        />
      </div>
    </div>
  );
};

export const Intro = ({}: {}) => {
  return (
    <div id="intro" className="bg-gray-900 px-6 py-10 sm:py-20 lg:px-8">
      <div className="mx-auto max-w-2xl text-center">
        <h2 className="text-5xl font-semibold tracking-tight text-white sm:text-7xl">
          Intro
        </h2>
        <p className="mt-8 text-pretty text-lg font-medium text-gray-400 sm:text-xl/8">
          Our Partner Program is designed for go-getters who believe in the
          power of connection. By introducing event organizers to our platform,
          you’ll not only help them succeed but also earn rewards for every
          ticket they sell. Whether you're a seasoned professional in the events
          industry or simply someone with a wide network, this program is your
          opportunity to make a lasting impact and generate ongoing income.
        </p>
      </div>
    </div>
  );
};

export const HowItWorks = ({}: {}) => {
  return (
    <Container>
      <section>
        <h3 className="text-4xl text-white text-center font-bold">
          How It Works
        </h3>
        <div className="flex sm:items-center gap-1 sm:gap-3 mt-5">
          <div className="w-1/3 flex flex-col gap-1 items-center">
            <PencilIcon className="sm:w-8 sm:h-8 w-4 h-4 text-white" />
            <span className="text-white font-bold text-center">Sign Up</span>
            <p className="text-center text-white hidden sm:block text-xs sm:text-sm">
              Join the program by completing our quick and easy registration
              process.
            </p>
          </div>
          <ArrowRightIcon className="w-10 h-10 text-white" />
          <div className="w-1/3 flex flex-col gap-1 items-center">
            <ShareIcon className="sm:w-8 sm:h-8 w-4 h-4 text-white" />
            <span className="text-white font-bold text-center">
              Share the Platform
            </span>
            <p className="text-center hidden sm:block text-xs sm:text-sm text-white">
              Introduce event organizers and vendors to our platform using your
              unique referral link.
            </p>
          </div>
          <ArrowRightIcon className="w-10 h-10 text-white" />
          <div className="w-1/3 flex flex-col gap-1 items-center">
            <CurrencyDollarIcon className="sm:w-8 sm:h-8 w-4 h-4 text-white" />
            <span className="text-white font-bold text-center">
              Earn on Every Sale
            </span>
            <p className="text-center hidden sm:block text-xs sm:text-sm text-white">
              For every ticket sold by the vendors you bring on board, you’ll
              earn $0.10. Watch your earnings grow as their events thrive.
            </p>
          </div>
        </div>
      </section>
    </Container>
  );
};

export const RevenueCalculator = () => {
  const [numVendors, setNumVendors] = useState(1);
  const [avgTicketsSold, setAvgTicketsSold] = useState(100);

  return (
    <div className="bg-white shadow-md rounded-lg p-8 max-w-xl mx-auto mt-16">
      <h4 className="text-xl text-gray-800 text-center font-bold mb-6">
        Crunch the Numbers
      </h4>
      <section className="space-y-6">
        <div className="space-y-4">
          {/* Number of Vendors Input */}
          <div className="flex items-center justify-between">
            <label className="text-gray-700 font-medium">
              Number of Vendors
            </label>
            <input
              className="border border-gray-300 rounded-lg p-2 w-32 focus:outline-none focus:ring-2 focus:ring-blue-500"
              type="number"
              value={numVendors}
              min="1"
              onChange={(e) =>
                setNumVendors(Math.max(1, parseInt(e.target.value) || 1))
              }
            />
          </div>

          {/* Average Tickets Sold Input */}
          <div className="flex items-center justify-between">
            <label className="text-gray-700 font-medium">
              Average Monthly Tickets Sold per Vendor
            </label>
            <input
              className="border border-gray-300 rounded-lg p-2 w-32 focus:outline-none focus:ring-2 focus:ring-blue-500"
              type="number"
              value={avgTicketsSold}
              min="1"
              onChange={(e) =>
                setAvgTicketsSold(Math.max(1, parseInt(e.target.value) || 1))
              }
            />
          </div>
        </div>

        {/* Estimated Earnings */}
        <div className="bg-gray-100 rounded-lg p-4 text-center space-y-4">
          <div>
            <span className="block text-gray-700 font-medium">
              Est. Monthly Earnings
            </span>
            <span className="block text-5xl font-bold text-blue">
              {formatCurrency(numVendors * (avgTicketsSold * 0.1 * 100))}
            </span>
          </div>
          <div>
            <span className="block text-gray-700 font-medium">
              Est. Yearly Earnings
            </span>
            <span className="block text-5xl font-bold text-purple">
              {formatCurrency(numVendors * avgTicketsSold * 0.1 * 12 * 100)}
            </span>
          </div>
        </div>
      </section>
    </div>
  );
};

export const CTA = ({
  handleShowPartnerForm,
}: {
  handleShowPartnerForm: () => void;
}) => {
  return (
    <Container>
      <section className="text-center mt-16">
        <h3 className="text-4xl text-gray-900 font-bold">
          Start your Journey Today
        </h3>
        <p className="mt-5 text-gray-900 text-lg font-medium">
          Join our Partner Program and take the first step toward building your
          passive income.
        </p>
        <Button
          onClick={handleShowPartnerForm}
          variant="primary"
          className="mt-8"
        >
          Apply Now to Become a Partner
        </Button>
      </section>
    </Container>
  );
};

export const NewPartner = ({}: {}) => {
  const [showSuccessScreen, setShowSuccessScreen] = useState(false);
  return (
    <>
      <div className="max-w-xl mx-auto py-10">
        {showSuccessScreen ? (
          <div className="flex flex-col items-center justify-center h-[calc(100vh-10rem)]">
            <span className="text-3xl font-bold text-gray-600 text-center block">
              Thank you for your interest in our Partner Program!
            </span>
            <p className="text-gray-600 text-center mt-5">
              We have received your application and will review it shortly. You
              will receive an email with further instructions once your
              application is approved.
            </p>
          </div>
        ) : (
          <div className="max-w-xl mx-auto py-10 px-5">
            <span className="text-3xl font-bold text-gray-600 text-center block">
              Partner with Us
            </span>
            <PartnerRequestForm onSuccess={() => setShowSuccessScreen(true)} />
          </div>  
        )}
      </div>
    </>
  );
};
