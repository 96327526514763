import { Button } from "components/buttons";
import { TextInput } from "components/forms";
import { Spinner } from "components/loaders/Spinner";
import { values } from "lodash";
import { useAuth } from "providers/AuthContext";
import { useNotification } from "providers/NotificationContext";
import { useEffect, useState } from "react";
import { makeApiRequest } from "utils/api";
import { setUser } from "utils/user";
export const OtpForm = ({showOtp}: {showOtp?: (show: boolean) => void}) => {
  const { showError } = useNotification();
  const {setCurrentUser} = useAuth();
  const [showVerification, setShowVerification] = useState<boolean>(false);
  const [phoneNumber, setPhoneNumber] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [verificationCode, setVerificationCode] = useState<string>("");

  const sendOtp = () => {
    setLoading(true);
    makeApiRequest({
      path: "/send_otp",
      method: "POST",
      params: {
        phone_number: phoneNumber,
      },
    })
      .then((res) => {
        if (res.status === 200) {
          setShowVerification(true);
        }
      })
      .catch((err) => {
        const message = err?.data.message;
        showError(`${message} Please try your email instead.`);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const verifyOtp = () => {
    const otp = values(verificationCode).join("");
    setLoading(true);
    makeApiRequest({
      path: "/verify_otp",
      method: "POST",
      params: {
        phone_number: phoneNumber,
        otp: otp,
      },
    })
      .then((res) => {
        const user = res.data.user;
        const jwt = res.data.jwt;
        const userData = {
          id: user.id,
          jwt: jwt,
        };
        setUser(userData);
        setCurrentUser(user);
      })
      .catch((err) => {
        const message = err?.data.message;
        showError(message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    const idk = values(verificationCode).join("");
    if (idk.length === 6) {
      verifyOtp();
    }
  }, [verificationCode]);

  if (showVerification) {
    return (
      <div className="py-10">
        <div className="text-center">
          <h3 className="text-lg font-extrabold">Enter your OTP</h3>
          <p className="text-sm text-gray-500">
            We have sent a one-time code to your phone. Please enter the code
            below.
          </p>
        </div>
        <div className="mt-5 relative">
          <div className="flex items-center justify-center gap-2">
            <input
              value={verificationCode}
              autoComplete="one-time-code"
              onChange={(e) => {
                setVerificationCode(e.target.value);
              }}
              placeholder="Enter Code"
              maxLength={6}
              autoFocus={true}
              type="text"
              inputMode="numeric"
              className="w-full h-12 border border-gray-300 rounded-md focus:border-purple focus:ring-purple"
            />
          </div>
          {loading ? (
            <span className="flex w-fit items-center gap-2 absolute -bottom-12 mx-auto left-0 right-0">
              <Spinner />
              Verifying
            </span>
          ) : (
            <span></span>
          )}
        </div>
      </div>
    );
  }

  return (
    <div>
      <div className="text-center">
        <h3 className="text-lg font-extrabold">Log in with SMS.</h3>
        <p className="text-sm text-gray-500">
          We will send you a code to login to your account.
        </p>
      </div>
      <div className="mt-5">
        <TextInput
          value={phoneNumber}
          onChange={(e) => setPhoneNumber(e.target.value)}
          label="Phone Number"
          name="phone_number"
          type="text"
          placeholder="Phone Number"
          className="w-full border border-gray-300 rounded-md p-2"
        />
      </div>
      <div className="mt-5 flex justify-center gap-2">
        <Button
          variant="primary"
          disabled={loading}
          onClick={() => {
            sendOtp();
          }}
          className="w-full"
        >
          {loading ? "Sending..." : "Send Code"}
        </Button>

        {showOtp && (
          <Button
            data-testid="use-email-button"
            variant="default"
            onClick={() => {
              showOtp(false);
            }}
            className="w-full"
          >
            Use Email
          </Button>
        )}
      </div>
    </div>
  );
};
