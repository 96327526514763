import { Checkbox } from "@headlessui/react";
import { Button } from "components/buttons";
import { TextArea, TextInput } from "components/forms";
import { Spinner } from "components/loaders/Spinner";
import { Formik } from "formik";
import { set } from "lodash";
import { useNotification } from "providers/NotificationContext";
import { useState } from "react";
import { Link } from "react-router-dom";
import { makeApiRequest } from "utils/api";
import { validatePhoneNumber } from "utils/string";

export const PartnerRequestForm = ({
  onSuccess,
}: {
  onSuccess: () => void;
}) => {
  const { showError, showSuccess } = useNotification();
  const [loading, setLoading] = useState<boolean>(false);
  const [tos, setTos] = useState<boolean>(false);
  return (
    <>
      <Formik
        initialValues={{
          full_name: "",
          email: "",
          phone_number: "",
          note: "",
        }}
        onSubmit={(values) => {
          if (
            values.full_name === "" ||
            values.email === "" ||
            values.phone_number === ""
          ) {
            showError("Please fill out all required fields.");
            return;
          }

          if (!validatePhoneNumber(values.phone_number)) {
            showError("Please enter a valid phone number.");
            return;
          }
          setLoading(true);
          makeApiRequest({
            path: "/partner_requests",
            method: "POST",
            params: {
              partner_request: values,
            },
          })
            .then(() => {
              onSuccess();
            })
            .catch((e) => {
              const message =
                e.response?.data?.message ||
                "There was an error submitting your request.";
              showError(message);
            })
            .finally(() => {
              setLoading(false);
            });
        }}
      >
        {({ handleChange, handleBlur, handleSubmit, values }) => (
          <form onSubmit={handleSubmit} className="flex flex-col space-y-4">
            <div>
              <TextInput
                placeholder="Full Name"
                type="text"
                name="full_name"
                label={"Full Name*"}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.full_name}
              />
            </div>
            <div>
              <TextInput
                placeholder="Email"
                type="email"
                name="email"
                label={"Email*"}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.email}
              />
            </div>

            <div>
              <TextInput
                placeholder="Phone #"
                type="tel"
                name="phone_number"
                label={"Phone Number*"}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.phone_number}
              />
            </div>

            <div>
              <TextArea
                placeholder="Say a few words about yourself if you'd like."
                name="note"
                label={"Note"}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.note}
                rows={4}
              />
            </div>
            <span className="flex gap-3 py-3 text-xs">
              <span>
                By Checking this box, you agree to our{" "}
                <Link
                  to="/partner-agreement"
                  target="_blank"
                  rel="noreferrer"
                  className="text-blue"
                >
                  Partner Agreement
                </Link>
                .
              </span>
              <input
                type="checkbox"
                checked={tos}
                onChange={() => setTos(!tos)}
                className="w-4 h-4 rounded border"
              />
            </span>
            <Button
              disabled={loading || !tos}
              variant="primary"
              type="submit"
              className="relative"
            >
              Submit
              {loading && (
                <span className="absolute my-auto right-2">
                  <Spinner />
                </span>
              )}
            </Button>
          </form>
        )}
      </Formik>
    </>
  );
};
