const videoUrl = "https://lvgweb.s3.us-east-2.amazonaws.com/happy/happy_overview_4k.mp4"

export const MarketingVideo = ({width, autoPlay}: {width: number, autoPlay?: boolean}) => {
  return (
    <>
      <video controls autoPlay={autoPlay} width={width} poster={"//lvgweb.s3.us-east-2.amazonaws.com/happy/happie-guy-colored.svg"}>
        <source src={videoUrl} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    </>
  );
};
