export const VendorAgreement = () => {
  return (
    <div className="container mx-auto p-6 h-96 overflow-y-scroll border border-gray-300 rounded-lg">
      <h1 className="text-2xl font-bold text-center mb-2">
        HAPPY TICKETING SERVICE AGREEMENT
      </h1>

      <p className="mb-4">
        This Service Agreement ("Agreement") is entered into by and between
        Happy Ticketing, LLC ("HAPPY," "we," or "us") and the entity identified
        during the registration process ("Client," "you," or "your"). By
        clicking "I Agree," the individual accepting this Agreement represents
        and warrants that they are authorized to bind the Client to this
        Agreement, and that the Client agrees to be bound by its terms.
      </p>
      <p className="mb-4">
        HAPPY provides a platform to allow the Client to make online ticket
        sales to its customers ("Customers"). For good and valuable
        consideration, the receipt and sufficiency of which are hereby
        acknowledged, the parties agree as follows:
      </p>

      <h2 className="text-xl font-semibold mb-2">1. Definitions</h2>
      <ul className="list-disc list-inside mb-4">
        <li>
          <strong>"Agreement"</strong>: This Service Agreement and any
          amendments or attachments hereto.
        </li>
        <li>
          <strong>"Client"</strong>: You, the entity or individual entering into
          this Agreement with HAPPY.
        </li>
        <li>
          <strong>"Customer"</strong>: Any end-user who purchases tickets
          through the HAPPY Platform.
        </li>
        <li>
          <strong>"Event"</strong>: Any event organized by the Client for which
          tickets are sold via the Platform.
        </li>
        <li>
          <strong>"HAPPY"</strong>: Happy Ticketing, LLC.
        </li>
        <li>
          <strong>"Platform"</strong>: The online ticketing system provided by
          HAPPY.
        </li>
        <li>
          <strong>"Services"</strong>: The services provided by HAPPY under this
          Agreement.
        </li>
      </ul>

      <h2 className="text-xl font-semibold mb-2">2. Scope of Agreement</h2>
      <p className="mb-4">
        The Client agrees to use HAPPY as its online ticketing provider via an
        online box office that HAPPY shall provide.
      </p>

      <h2 className="text-xl font-semibold mb-2">3. Fees and Payment Terms</h2>

      <h3 className="text-lg font-medium mb-2">3.1 Online Transactions</h3>
      <ul className="list-disc list-inside mb-4">
        <li>
          <strong>Ticket Face Value</strong>: Less any discounts applied.
        </li>
        <li>
          <strong>Processing Fees</strong>: 2.99% of the total order + $0.30
        </li>
        <li>
          <strong>HAPPY Fee</strong>: $1.50 per
          ticket
        </li>
        <li>
          <strong>Sales Tax</strong>: Calculated based on the event's zip code
          (if applicable)
        </li>
      </ul>

      <h3 className="text-lg font-medium mb-2">
        3.2 Point of Sale (POS) Transactions
      </h3>
      <ul className="list-disc list-inside mb-4">
        <li>
          <strong>Ticket Face Value</strong>: Less any discounts applied.
        </li>
        <li>
          <strong>HAPPY Fee</strong>: $1.50 per
          ticket
        </li>
        <li>
          <strong>Processing Fees (Ticket orders)</strong>: 2.99% of the total
          order + $0.30
        </li>
        <li>
          <strong>Processing Fees (Concession orders)</strong>: 2.7% of the
          total order + $0.15
        </li>
        <li>
          <strong>Sales Tax</strong>: Calculated based on the event's zip code
          (if applicable)
        </li>
      </ul>

      <h3 className="text-lg font-medium mb-2">3.3 Payment to Client</h3>
      <p className="mb-4">
        The full base ticket price of tickets sold (less any discounts, cash
        fees owed, and refunds, plus any taxes collected) will be paid to you
        within thirty (30) days after the event is concluded.
      </p>

      <h3 className="text-lg font-medium mb-2">
        3.4 Sales Tax Responsibilities
      </h3>
      <p className="mb-4">
        HAPPY will collect sales tax based on the event's zip code if applicable
        and activated by the Client. The remittance of collected sales tax to
        the appropriate authorities is the sole responsibility of the Client.
        HAPPY is not liable for any sales tax remittance of any kind.
      </p>

      <h2 className="text-xl font-semibold mb-2">4. Services Provided</h2>
      <p className="mb-4">
        HAPPY will provide an online ticketing platform along with cloud
        hosting, technical services, and support for you. HAPPY will also
        provide customer support to the best of its ability if your customers
        contact HAPPY directly.
      </p>

      <h2 className="text-xl font-semibold mb-2">5. Client Responsibilities</h2>

      <h3 className="text-lg font-medium mb-2">5.1 Compliance with Laws</h3>
      <p className="mb-4">
        The Client agrees to comply with all applicable laws and regulations in
        connection with its use of the HAPPY Platform and the organization of
        Events. The Client is solely responsible for obtaining all necessary
        permits, licenses, and approvals required to host and operate Events.
      </p>

      <h3 className="text-lg font-medium mb-2">5.2 Accurate Information</h3>
      <p className="mb-4">
        The Client shall provide accurate and complete information in connection with
        the sale of tickets and shall not engage in any fraudulent, illegal, abusive,
        or unauthorized activities, or any activities that may harm the reputation,
        operation, or functionality of HAPPY. In the event of such activities, HAPPY
        reserves the right to suspend or terminate access to the Platform without
        prior notice and the Client agrees to indemnify and hold HAPPY harmless from
        any losses, damages, or claims arising from such activities.
      </p>

      <h3 className="text-lg font-medium mb-2">5.3 Ticket Validity</h3>
      <p className="mb-4">
        You are responsible for honoring all valid tickets sold through the
        HAPPY Platform that have not been altered and were paid for in full
        without dispute.
      </p>

      <h2 className="text-xl font-semibold mb-2">
        6. Refunds and Event Policies
      </h2>
      <h3 className="text-lg font-medium mb-2">6.1 Client's Policies</h3>
      <p className="mb-4">
        You are responsible for any refunds and for implementing a refund policy
        and any other event-related policies.
      </p>

      <h3 className="text-lg font-medium mb-2">6.2 Visibility of Policies</h3>
      <p className="mb-4">
        These policies must be visibly displayed on your event listing page
        within the HAPPY Platform and on your own website, and conveyed to any
        customers who purchase tickets through the HAPPY Platform.
      </p>

      <h3 className="text-lg font-medium mb-2">6.3 Refund Process</h3>
      <ul className="list-disc list-inside mb-4">
        <li>
          <strong>Refund Authorization</strong>: Refunds can be authorized
          before the event has started, up to the ticket base price (processing
          and HAPPY fees excluded).
        </li>
        <li>
          <strong>Refund Deductions</strong>: Any refunds will be deducted from
          your account balance.
        </li>
        <li>
          <strong>Time Limitations</strong>: Refunds will not be allowed once
          the event has started.
        </li>
      </ul>

      <h3 className="text-lg font-medium mb-2">7. Service Availability</h3>
      <p className="mb-4">
        HAPPY will use commercially reasonable efforts to maintain the availability
        of the Platform. However, HAPPY does not guarantee that the Platform will be
        available at all times, and HAPPY shall not be liable for any damages
        resulting from any interruption, suspension, or termination of the Platform,
        regardless of the cause. The Client acknowledges that the Platform may be
        subject to limitations, delays, and other problems inherent in the use of the
        internet and electronic communications.
      </p>

      <h3 className="text-lg font-medium mb-2">8. Intellectual Property Rights</h3>
      <ul className="list-disc list-inside mb-4">
        <li>
          <strong>Ownership by HAPPY</strong>: HAPPY retains full control and all
          rights, title, and interest in and to the digital ticket property and the
          Platform, including all software, code, algorithms, and any associated
          intellectual property rights, including ad space.
        </li>
        <li>
          <strong>License to Client</strong>: HAPPY grants the Client a limited,
          non-exclusive, non-transferable license to access and use the Platform
          solely for purposes outlined in this Agreement.
        </li>
        <li>
          <strong>Client Content</strong>:
          <ul className="list-disc list-inside ml-4">
            <li>
              <strong>Ownership</strong>: The Client retains all rights to its own
              content provided to HAPPY.
            </li>
            <li>
              <strong>License to HAPPY</strong>: The Client grants HAPPY a
              non-exclusive, worldwide, royalty-free license to use, reproduce, and
              display the Client's content as necessary to provide the Services under
              this Agreement.
            </li>
          </ul>
        </li>
      </ul>

      <h3 className="text-lg font-medium mb-2">9. Data Protection and Privacy</h3>
      <ul className="list-disc list-inside mb-4">
        <li>
          <strong>Compliance with Laws</strong>: Both parties agree to comply with
          all applicable data protection and privacy laws, including but not limited
          to the General Data Protection Regulation (GDPR) and the California
          Consumer Privacy Act (CCPA).
        </li>
        <li>
          <strong>Use of Customer Data</strong>:
          <ul className="list-disc list-inside ml-4">
            <li>
              <strong>Purpose Limitation</strong>: HAPPY will use Customer data
              solely for the purposes of providing Services under this Agreement.
            </li>
            <li>
              <strong>Data Sharing</strong>: HAPPY may aggregate your event
              information on the HAPPY website and app, and will not share personally
              identifiable Customer data with third parties except as necessary to
              provide the Services or as required by law.
            </li>
          </ul>
        </li>
        <li>
          <strong>Data Security</strong>: HAPPY will implement reasonable
          administrative, technical, and physical safeguards to protect Customer data
          from unauthorized access, disclosure, alteration, or destruction.
        </li>
      </ul>

      <h3 className="text-lg font-medium mb-2">10. Confidentiality</h3>
      <p className="mb-4">
        Both parties agree to keep confidential and not disclose to any third party,
        except as required by law or as necessary to perform this Agreement, any
        confidential information provided by the other party. Confidential
        information includes, but is not limited to, business plans, customer lists,
        financial information, and any other proprietary information.
        The obligations of confidentiality under this Section shall survive termination of this Agreement for a period of two (2) years
      </p>

      <h3 className="text-lg font-medium mb-2">11. Indemnification</h3>
      <ul className="list-disc list-inside mb-4">
        <li>
          <strong>Indemnification by Client</strong>: The Client agrees to indemnify,
          defend, and hold harmless HAPPY, its affiliates, officers, directors,
          employees, and agents from any and all claims, liabilities, damages,
          losses, or expenses (including reasonable attorneys' fees and costs)
          arising out of or in any way connected with:
          <ul className="list-disc list-inside ml-4">
            <li>The Client's use of the HAPPY Platform.</li>
            <li>The Client's violation of this Agreement.</li>
            <li>
              Any breach of the Client's representations and warranties set forth
              herein.
            </li>
            <li>The Client's violation of any applicable law or regulation.</li>
            <li>
              Any claim brought by a third party arising out of an Event organized by
              the Client.
            </li>
            
          
          </ul>
            The Client agrees to indemnify HAPPY against any third-party claims arising from the Client's use of the Platform, including but not limited to claims of intellectual property infringement or violations of consumer protection laws.
        </li>
      </ul>

      <h3 className="text-lg font-medium mb-2">12. Limitation of Liability</h3>
      <ul className="list-disc list-inside mb-4">
        <li>
          <strong>Liability Cap</strong>: In no event shall HAPPY be liable for any
          direct, indirect, incidental, consequential, special, or punitive damages,
          including but not limited to loss of profits, revenue, or data, arising out
          of or in connection with this Agreement or the use of the HAPPY Platform,
          whether or not HAPPY has been advised of the possibility of such damages.
          HAPPY's total cumulative liability arising out of or relating to this
          Agreement shall not exceed the total fees paid by the Client to HAPPY in
          the three (3) months preceding the claim.
        </li>
      </ul>

      <h3 className="text-lg font-medium mb-2">13. Disclaimer of Warranties</h3>
      <ul className="list-disc list-inside mb-4">
        <li>
          <strong>"As Is" Basis</strong>: The Platform and Services are provided on
          an "as is" and "as available" basis.
        </li>
        <li>
          <strong>No Warranties</strong>: Any warranties, whether express or implied,
          that are not specifically stated herein are hereby disclaimed. HAPPY
          expressly disclaims all warranties of any kind, whether express or implied,
          including but not limited to the implied warranties of merchantability,
          fitness for a particular purpose, and non-infringement.
        </li>
      </ul>

      <h3 className="text-lg font-medium mb-2">14. Force Majeure</h3>
      <p className="mb-4">
        Neither party shall be liable for any failure or delay in performance under
        this Agreement due to causes beyond its reasonable control, including but
        not limited to acts of God, terrorism, war, riots, fire, earthquake,
        pandemic, governmental action, or any other unforeseen and uncontrollable
        event (a "Force Majeure Event").
      </p>
      <p className="mb-4">
        This limitation shall not apply to any payment obligations that were due
        and payable prior to the occurrence of the Force Majeure Event. The affected
        party shall notify the other party promptly of any such Force Majeure Event
        and shall use commercially reasonable efforts to mitigate the impact of the
        event on its performance.
      </p>

      <h3 className="text-lg font-medium mb-2">15. Marketing and Promotion</h3>
      <p className="mb-4">
        HAPPY may use your name, website address, public social media posts, and
        tickets available for sale publicly by you, together with aggregate data
        related thereto, to promote ticket sales and for reporting and compliance
        purposes. The Client may opt out of such use by providing written notice to HAPPY.
      </p>

      <h3 className="text-lg font-medium mb-2">16. Governing Law and Dispute Resolution</h3>
      <p className="mb-4">
        This Agreement shall be governed by and construed in accordance with the
        laws of the State of Florida, without regard to its conflict of law
        principles.
      </p>
      <ul className="list-disc list-inside mb-4">
        <li>
          <strong>Dispute Resolution</strong>: Any disputes arising out of or in
          connection with this Agreement shall be resolved as follows:
          <ul className="list-disc list-inside ml-4">
            <li>
              <strong>Negotiation</strong>: The parties shall first attempt in good
              faith to resolve any dispute through negotiation.
            </li>
            <li>
              <strong>Arbitration</strong>: If the dispute cannot be resolved through
              negotiation within thirty (30) days, they agree to submit to binding
              arbitration in Saint Petersburg, Florida, in accordance with the rules
              of the American Arbitration Association (AAA).
              <ul className="list-disc list-inside ml-4">
                <li>
                  <strong>Venue</strong>: The arbitration shall take place in Saint
                  Petersburg, Florida.
                </li>
                <li>
                  <strong>Rules</strong>: The arbitration shall be conducted in
                  accordance with the AAA Commercial Arbitration Rules.
                </li>
                <li>
                  <strong>Final and Binding</strong>: The arbitration award shall be
                  final and binding on both parties.
                </li>
                <li>
                  <strong>Enforcement</strong>: Judgment on the arbitration award may
                  be entered in any court having jurisdiction.
                </li>
              </ul>
            </li>
          </ul>
        </li>
        <li>
          <strong>Jurisdiction</strong>: If court intervention is necessary, the
          parties consent to the exclusive jurisdiction and venue of the state and
          federal courts located in Saint Petersburg, Florida.
        </li>
      </ul>


      <h3 className="text-lg font-medium mb-2">17. Third-Party Services</h3>
      <p className="mb-4">
        HAPPY may integrate with or rely on third-party services to provide the
        Platform and Services. HAPPY makes no representations or warranties regarding
        these third-party services and shall not be liable for any issues or damages
        arising from their use. The Client acknowledges that their use of such
        services is subject to the terms and conditions of the third parties.
        Any failure or disruption of third-party services shall not be considered a breach of HAPPY’s obligations under this Agreement.
      </p>

      <h3 className="text-lg font-medium mb-2">18. Event Responsibility</h3>
      <p className="mb-4">
        The Client is solely responsible for the organization, management, and
        execution of its Events, including but not limited to venue arrangements,
        permits, licenses, and safety protocols. HAPPY shall not be liable for any
        claims, damages, or losses arising from the Client's Events, including
        cancellations, postponements, or failure to fulfill obligations to Customers.
      </p>

      <h3 className="text-lg font-medium mb-2">19. Termination</h3>
      <ul className="list-disc list-inside mb-4">
        <li>
          <strong>Termination for Convenience</strong>: Either party may terminate
          this Agreement for any reason upon fifteen (15) days’ written notice to the
          other party.
        </li>
        <li>
          <strong>Termination for Cause</strong>: Either party may terminate this
          Agreement immediately upon written notice if the other party breaches any
          material term of this Agreement and fails to cure such breach within
          fifteen (15) days after receiving written notice of the breach.
        </li>
        <li>
          <strong>Immediate Termination by HAPPY</strong>: HAPPY may terminate this
          Agreement immediately upon written notice if the Client engages in
          fraudulent or illegal activities, or in any activities that, in HAPPY’s
          sole discretion, may harm HAPPY’s reputation, business operations, or the
          integrity of the Platform.
        </li>
        <li>
          <strong>Effect of Termination</strong>:
          <ul className="list-disc list-inside ml-4">
            <li>
              <strong>Access Termination</strong>: The Client’s access to the Platform
              will be disabled immediately upon termination.
            </li>
            <li>
              <strong>Settlement of Accounts</strong>: Any outstanding balances owed
              to HAPPY shall become immediately due and payable. Any remaining funds
              owed to the Client will be disbursed within thirty (30) days after all
              obligations are settled.
            </li>
            <li>
              <strong>Post-Termination Obligations</strong>: Upon termination, the
              Client shall immediately cease all use of the Platform and remove any
              references to HAPPY from its materials.
            </li>
            <li>
              <strong>Survival</strong>: The provisions of Sections 9 (Data Protection
              and Privacy), 10 (Confidentiality), 11 (Indemnification), 12 (Limitation
              of Liability), 16 (Governing Law and Dispute Resolution), and this
              Section 19 (Termination) shall survive termination.
            </li>
          </ul>
        </li>
      </ul>


      <h3 className="text-lg font-medium mb-2">20. Changes to Services</h3>
      <p className="mb-4">
        HAPPY reserves the right to modify, suspend, or discontinue any part of the
        Platform or Services at its sole discretion, with reasonable notice provided
        to the Client. HAPPY shall not be liable for any damages or losses resulting
        from such changes.
      </p>

      <h3 className="text-lg font-medium mb-2">21. Tax Compliance</h3>
      <p className="mb-4">
        While HAPPY collects sales tax based on the event's zip code if activated by
        the Client, the Client is solely responsible for ensuring full compliance
        with all applicable tax laws and regulations, including reporting and
        remittance of sales tax. HAPPY disclaims any liability related to the
        Client's tax obligations.
      </p>


    </div>
  );
};
