import { Button } from "components/buttons";
import { Spinner } from "components/loaders/Spinner";
import { useEffect, useState } from "react";
import ConfettiExplosion from "react-confetti-explosion";
import { Link, useNavigate } from "react-router-dom";
import { makeApiRequest } from "utils/api";
import { motion } from "framer-motion";
import Breadcrumbs from "./Breadcrumbs";
import { Modal } from "components/layout";
import { useNotification } from "providers/NotificationContext";
import { VendorAgreement } from "components/VendorAgreement";

type RequestProps = {
  first_name: string | null;
  last_name: string | null;
  email: string | null;
  org_name: string | null;
  password: string | null;
  partner_code: string | null;
};

export const Org = ({}: {}) => {
  const [orgName, setOrgName] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [showConfirmation, setShowConfirmation] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [tos, setTos] = useState<boolean>(false);
  const [showTosModal, setShowTosModal] = useState<boolean>(false);

  const navigate = useNavigate();

  const { showError } = useNotification();

  const onHandleContinue = () => {
    const first_name = localStorage.getItem("firstName") || null;
    const last_name = localStorage.getItem("lastName") || null;
    const email = localStorage.getItem("email") || null;
    if (orgName && first_name && last_name && email && password) {
      const apiPayload: RequestProps = {
        first_name: localStorage.getItem("firstName"),
        last_name: localStorage.getItem("lastName"),
        email: localStorage.getItem("email"),
        org_name: orgName,
        password: password,
        partner_code: localStorage.getItem("partner_code") || null,
      };
      setLoading(true);
      makeApiRequest({
        method: "POST",
        path: "/onboard_org",
        params: apiPayload,
      })
        .then((res) => {
          setOrgName("");
          if (res.status === 200) {
            // clear local storage and show confirmation
            localStorage.clear();
            setShowTosModal(false);
            setShowConfirmation(true);
          }
        })
        .catch((err) => {
          const message = err.data.message;
          showError(message);
        })
        .finally(() => {
          setLoading(false);
        });
    }

    return null;
  };
  useEffect(() => {
    if (
      !localStorage.getItem("firstName") ||
      !localStorage.getItem("firstName")
    ) {
      navigate("/get-started");
    }

    if (!localStorage.getItem("email")) {
      navigate("/get-started/email");
    }
  }, []);
  return (
    <>
      {!showConfirmation && <Breadcrumbs />}
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        className="w-full flex justify-center items-center pt-20"
      >
        <div className="w-full md:max-w-lg">
          {showConfirmation ? (
            <>
              <ConfettiExplosion
                width={window.innerWidth}
                colors={["#f72585", "#7209b7", "#4361ee", "#4cc9f0", "#FFBA08"]}
                duration={7000}
              />
              <h1 className="text-purple text-4xl font-bold text-center">
                Get Happy! <br />
                You’re one step away.
              </h1>
              <div className="mt-10 flex flex-col h-32 md:h-24 justify-between items-center">
                <p className="text-xl text-center">
                  Click below to access your dashboard and start selling! 🤑
                </p>

                <a href={`${import.meta.env.VITE_ADMIN_WEB_URL}`}>
                  <Button variant="pink">Log In to Vendor Account</Button>
                </a>
              </div>
            </>
          ) : (
            <>
              <h1 className="text-purple text-4xl font-bold text-center">
                What's the name of your organization?
              </h1>
              <div className="mt-10 flex flex-col h-60 justify-between">
                <div>
                  <input
                    autoFocus={true}
                    value={orgName}
                    onChange={(e) => setOrgName(e.target.value)}
                    type="text"
                    name="orgName"
                    autoComplete="off"
                    className="w-full h-16 border placeholder:text-xl text-xl rounded-lg"
                    placeholder="Organization Name"
                  />
                </div>
                {orgName.length ? (
                  <div className="flex items-end">
                    <div className="grow">
                      <label className="font-bold flex">
                        Enter a password to complete your account
                      </label>
                      <span className="block pb-3 text-xs">{`(min 8 characters)`}</span>
                      <input
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            onHandleContinue();
                          }
                        }}
                        autoComplete="off"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        type="password"
                        name="password"
                        className="w-full h-16 border placeholder:text-xl text-xl rounded-tl-lg rounded-bl-lg"
                        placeholder="Password"
                      />
                    </div>
                    <Button
                      disabled={password.length < 8}
                      onClick={() => setShowTosModal(true)}
                      variant="pink"
                      className="h-16 rounded-tl-none rounded-bl-none w-32 relative"
                    >
                      Continue
                    </Button>
                  </div>
                ) : null}
              </div>
            </>
          )}
        </div>
        <Modal
          size="screen"
          isOpen={showTosModal}
          onRequestClose={() => setShowTosModal(false)}
        >
          <VendorAgreement />
          <div className="flex items-start mt-5">
            <input
              type="checkbox"
              name="tos"
              id="tos"
              checked={tos}
              onChange={() => setTos(!tos)}
              className="mr-2"
            />
            <label htmlFor="tos" className="text-xs">
              I confirm that I am authorized to bind my organization to these terms as an
             authorized representative, user, or account administrator. By checking this box,
              I agree to these terms on behalf of my organization.
            </label>
          </div>
          <Button
            disabled={loading || !tos}
            onClick={onHandleContinue}
            variant="pink"
            className="h-16 w-full mt-10 relative"
          >
            {!tos ? 'You must agree to terms' : 'Finish'}
            {loading ? (
              <Spinner className=" dark:text-purple text-pink w-5 h-5 absolute right-12 top-0 bottom-0 m-auto" />
            ) : null}
          </Button>
        </Modal>
      </motion.div>
    </>
  );
};
