import React, { Fragment, useEffect } from "react";
import { Switch, Listbox, Transition } from "@headlessui/react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/24/solid";

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}

interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
  name: string;
  label?: string;
}

interface TextAreaProps
  extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {
  name: string;
  label?: string | null;
}

export type InputOption = {
  name?: string;
  value: any;
  display: string;
};

export const SelectInput = ({
  options,
  onSelect,
  label,
  placeholder,
  selectedOption,
  buttonStyle = "bg-white relative w-full border border-gray-400 shadow-sm pl-3 pr-10 h-10 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-purple focus:border-purple sm:text-lg",
}: {
  options: InputOption[];
  onSelect: (selected?: InputOption) => void;
  label?: string;
  placeholder?: string;
  selectedOption?: InputOption;
  buttonStyle?: string;
}) => {
  return (
    <div>
      <Listbox value={selectedOption} onChange={onSelect}>
        {({ open }) => (
          <>
            {label ? (
              <Listbox.Label className="mb-2 block">{label}</Listbox.Label>
            ) : null}
            <div className="relative">
              <Listbox.Button className={buttonStyle}>
                <span className="block truncate">
                  {selectedOption?.display || "Select ..."}
                </span>
                <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                  <ChevronUpDownIcon
                    className="h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                </span>
              </Listbox.Button>

              <Transition
                show={open}
                as={Fragment}
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Listbox.Options className="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-60 rounded-lg py-1 text-lg ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-lg">
                  {options.map((option) => (
                    <Listbox.Option
                      key={option.value}
                      className={({ active }) =>
                        classNames(
                          active ? "text-white bg-aqua" : "text-gray-900",
                          "cursor-default select-none relative py-2 pl-3 pr-9"
                        )
                      }
                      value={option}
                    >
                      {({ selected, active }) => (
                        <>
                          <span
                            className={classNames(
                              selected ? "font-semibold" : "font-normal",
                              "block truncate"
                            )}
                          >
                            {option.display}
                          </span>

                          {selected ? (
                            <span
                              className={classNames(
                                active ? "text-white" : "text-lime-600",
                                "absolute inset-y-0 right-0 flex items-center pr-4"
                              )}
                            >
                              <CheckIcon
                                className="h-5 w-5"
                                aria-hidden="true"
                              />
                            </span>
                          ) : null}
                        </>
                      )}
                    </Listbox.Option>
                  ))}
                </Listbox.Options>
              </Transition>
            </div>
          </>
        )}
      </Listbox>
    </div>
  );
};

export const Toggler = ({
  label,
  description,
  onToggle,
}: {
  label: string;
  description?: string;
  onToggle?: (enabled: boolean) => void;
}) => {
  const [enabled, setEnabled] = React.useState<boolean>(false);

  React.useEffect(() => {
    if (onToggle) {
      onToggle(enabled);
    }
  }, [enabled]);

  return (
    <Switch.Group as="div" className="flex items-center justify-between">
      <span className="flex-grow flex flex-col">
        <Switch.Label as="span" className=" font-medium text-gray-700" passive>
          {label}
        </Switch.Label>
        <Switch.Description as="span" className=" text-gray-500">
          {description}
        </Switch.Description>
      </span>
      <Switch
        checked={enabled}
        onChange={setEnabled}
        className={classNames(
          enabled ? "bg-green-400" : "bg-gray-200",
          "relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple"
        )}
      >
        <span
          aria-hidden="true"
          className={classNames(
            enabled ? "translate-x-5" : "translate-x-0",
            "pointer-events-none inline-block h-5 w-5 rounded-full bg-purple shadow transform ring-0 transition ease-in-out duration-200"
          )}
        />
      </Switch>
    </Switch.Group>
  );
};

export const TextArea = ({
  name,
  label,
  className,
  ...rest
}: TextAreaProps) => {
  return (
    <div className="">
      <label htmlFor={name} className="block  font-medium text-gray-700 mb-2">
        {label}
      </label>
      <div>
        <textarea
          id={name}
          className={`block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-purple sm:text-sm sm:leading-6 ${className}`}
          {...rest}
        />
      </div>
    </div>
  );
};

export const TextInput = ({ name, label, className, ...rest }: Props) => {
  return (
    <div>
      <label
        htmlFor={name}
        className={!label ? "sr-only" : "block font-medium text-gray-700 mb-2"}
      >
        {label}
      </label>
      <input
        name={name}
        id={name}
        className={`block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-purple sm:text-sm sm:leading-6 ${className}`}
        {...rest}
      />
    </div>
  );
};
