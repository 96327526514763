import {isValidPhoneNumber} from 'libphonenumber-js';
export function generateRandomString(length = 8): string {
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let result = '';
  
  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * characters.length);
    result += characters[randomIndex];
  }

  return result;
}

export const validatePhoneNumber = (phone_number: string): boolean => {
  return isValidPhoneNumber(phone_number, 'US');
}

export const formatInt = (bigInt = 0): string => {
  return bigInt.toLocaleString('en-US');
}