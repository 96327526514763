import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import { CalendarIcon } from "@heroicons/react/24/solid";
import { format } from "date-fns";
import {
  Field,
  FieldInputProps,
  FieldProps,
  Form,
  Formik,
  FormikErrors,
  useFormik,
  useFormikContext,
} from "formik";
import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { SearchFormProps } from ".";
import { PlacesAutoComplete, PropsValue } from "components/PlacesAutoComplete";
import { Option } from "react-google-places-autocomplete/build/types";

export const SearchBar = ({
  handleSearch,
  handleFieldUpdate,
  searchQuery
}: {
  handleSearch: () => void;
  handleFieldUpdate: (field: "date" | "search" | "location", value: string | Date) => void
  searchQuery: SearchFormProps
}) => {

  return (
    <>
      <div className="w-full flex sm:justify-center items-center rounded-xl md:py-10 flex-wrap">
        <input
          autoComplete="off"
          type="text"
          placeholder="Location"
          value={searchQuery.location}
          onChange={(e) => handleFieldUpdate("location", e.target.value)}
          className="w-1/2 md:w-1/3 lg:w-[20%] shadow-md border-r-0 bg-white text-xl p-4 bg-transparent placeholder:text-gray-300 md:rounded-tl-md md:rounded-bl-md"
        />

        <div className="w-[50%] md:w-1/3 lg:w-[20%] relative ">
          <DatePicker
            wrapperClassName="w-full"
            selected={searchQuery.date}
            onChange={(date) => handleFieldUpdate("date", date || "")}
            className="cursor-pointer shadow-md border-purple text-xl p-4 w-full"
          />
          <CalendarIcon className="w-6 absolute right-6 top-0 bottom-0 m-auto text-gray-500" />
        </div>
        <div className="relative w-full md:w-1/3 lg:w-[40%]">
          <MagnifyingGlassIcon className="w-6 absolute left-3 my-auto top-0 bottom-0 text-gray-400" />
          <input
            autoComplete="off"
            type="text"
            // value={searchQuery.search}
            placeholder="Search by Name or Venue"
            onChange={(e) => handleFieldUpdate("search", e.target.value)}
            className="w-full shadow-md bg-white md:border-l-0 text-xl p-4 pl-12 bg-transparent placeholder:text-gray-400 md:rounded-tr-md md:rounded-br-md"
          />
        </div>

        <button
          onClick={handleSearch}
          className="w-full lg:w-auto mt-3 lg:ml-auto lg:mt-0 py-3 px-12 h-[62px] bg-pink text-white rounded-lg font-bold text-xl shadow-md active:brightness-110 active:translate-y-0.5 active:shadow-none"
        >
          Search
        </button>
      </div>
    </>
  );
};

export const SearchForm = ({
  initialValues,
  onSubmit,
}: {
  initialValues: SearchFormProps
  onSubmit: (formValues: SearchFormProps) => void;
}) => {
  return (
    <>
      <label className="text-xl font-bold block w-full my-3 md:hidden">
        Find Events
      </label>
      <Formik initialValues={initialValues} onSubmit={onSubmit}>
        <FormContent location={initialValues?.location} />
      </Formik>
    </>
  );
};

export const FormContent = ({
  location,
}: {
  location: string | null | undefined;
}) => {
  const formik = useFormikContext<SearchFormProps>();

  useEffect(() => {
    formik.setFieldValue("location", location);
  }, [location]);
  return (
    <Form>
      <div className="w-full flex sm:justify-center items-center rounded-xl md:pb-10 flex-wrap">
        <Field name="location">
          {({ field }: { field: FieldInputProps<any> }) => {
            return (
              <PlacesAutoComplete onChange={(e) => formik.setFieldValue('location', e)} />
            );
          }}
        </Field>

        <div className="w-[50%] md:w-1/3 lg:w-[20%] relative ">
          <Field name="date">
            {({ field }: { field: FieldInputProps<any> }) => {
              return (
                <DatePicker
                {...field}
                onChange={(date) => formik.setFieldValue("date", date)}
                placeholderText="Event Date"
                wrapperClassName="w-full"
                selected={formik.values.date as Date}
                autoComplete="off"
                className="cursor-pointer border-gray-200 shadow-md text-xl p-4 w-full placeholder:text-gray-400 focus:outline-none focus:ring-0 focus:border-none"
                />
              );
            }}
          </Field>
          <CalendarIcon className="w-6 absolute right-6 top-0 bottom-0 m-auto text-gray-400" />
        </div>
        <div className="relative w-full md:w-1/3 lg:w-[40%]">
          <MagnifyingGlassIcon className="w-6 absolute left-3 my-auto top-0 bottom-0 text-gray-400" />
          <Field name="search">
            {({ field }: { field: FieldInputProps<string> }) => {
              return (
                <input
                  data-testid="search-events-by-name"
                  {...field}
                  autoComplete="off"
                  type="text"
                  placeholder="Search by Name or Venue"
                  className="focus:outline-none focus:ring-0 focus:border-none w-full shadow-md bg-white border-gray-200 md:border-l-0 text-xl p-4 pl-12 bg-transparent placeholder:text-gray-400 md:rounded-tr-md md:rounded-br-md"
                />
              );
            }}
          </Field>
        </div>

        <button
          type={"submit"}
          className="w-full lg:w-auto grow mt-3 lg:ml-3 lg:mt-0 py-3 px-12 h-[62px] bg-pink text-white rounded-lg font-bold text-xl shadow-md active:brightness-110 active:translate-y-0.5 active:shadow-none"
        >
          Search
        </button>
      </div>
    </Form>
  );
};
