import { Fragment, useEffect, useState } from "react";
import { Label, Listbox, ListboxButton, ListboxOption, ListboxOptions, Transition } from "@headlessui/react";
import { CheckIcon, ChevronDownIcon } from "@heroicons/react/20/solid";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Cog6ToothIcon,
  ListBulletIcon,
  PaperAirplaneIcon,
  TicketIcon,
} from "@heroicons/react/24/outline";
import { useAuth } from "providers/AuthContext";
import { BanknotesIcon } from "@heroicons/react/24/solid";

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}

export const AccountNavigationDropdown = () => {
  const { user } = useAuth();
  const location = useLocation();
  let accountLinks = [
    {
      title: "My Account",
      url: "/account/settings",
      description: "Personal information, saved cards, and account settings.",
      active: location.pathname === "/account/settings",
      icon: () => (
        <Cog6ToothIcon className="-ml-0.5 h-5 w-5" aria-hidden="true" />
      ),
    },
    {
      title: "My Tickets",
      url: "/account/tickets",
      description: "Tickets to upcoming events.",
      active: location.pathname === "/account/tickets",
      icon: () => <TicketIcon className="-ml-0.5 h-5 w-5" aria-hidden="true" />,
    },
    {
      title: "Ticket Transfers",
      url: "/account/ticket-transfers",
      description: "View your current and past ticket transfers.",
      active: location.pathname === "/account/ticket-transfers",
      icon: () => (
        <PaperAirplaneIcon className="-ml-0.5 h-5 w-5" aria-hidden="true" />
      ),
    },
    {
      title: "Order History",
      url: "/account/orders",
      active: location.pathname === "/account/orders",
      description: "View your order history and associated tickets.",
      icon: () => (
        <ListBulletIcon className="-ml-0.5 h-5 w-5" aria-hidden="true" />
      ),
    },
  ];

  if (user?.partner) {
    accountLinks.push({
      title: "Partner Dashboard",
      url: "/account/partner-dashboard",
      active: location.pathname === "/account/partner-dashboard",
      description: "View your partner dashboard.",
      icon: () => (
        <BanknotesIcon className="-ml-0.5 h-5 w-5" aria-hidden="true" />
      ),
    });
  }
  const navigate = useNavigate();
  const selected = accountLinks.find((link) => link.active);
  const handleSelect = (value: any) => {
    navigate(value.url);
  };

  return (
    <Listbox value={selected} onChange={handleSelect}>
      {({ open }) => (
        <>
          <Label className="sr-only">Select Account Page</Label>
          <div className="relative">
            <div className="inline-flex divide-x divide-purple rounded-md">
              <div className="inline-flex items-center gap-x-1.5 rounded-l-m py-2 px-3 text-purple">
                {selected?.icon()}
                <p className="text-sm font-semibold">{selected?.title}</p>
              </div>
              <ListboxButton className="rounded-lg inline-flex items-center  bg-purple p-2 hover:bg-brightness-110 focus:outline-none focus:ring-2 focus:ring-pink focus:ring-offset-2 focus:ring-offset-gray-50">
                <span className="sr-only">Select Account Page</span>
                <ChevronDownIcon
                  className="h-5 w-5 text-white"
                  aria-hidden="true"
                />
              </ListboxButton>
            </div>

            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <ListboxOptions className="absolute right-0 z-10 mt-2 w-72 origin-top-right divide-y divide-gray-200 overflow-hidden rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                {accountLinks.map((option) => (
                  <ListboxOption
                    key={option.title}
                    className={({ active }) =>
                      classNames(
                        active ? "bg-purple text-white" : "text-gray-900",
                        "cursor-default select-none p-4 text-sm"
                      )
                    }
                    value={option}
                  >
                    {({ selected, active }) => (
                      <div className="flex flex-col">
                        <div className="flex justify-between">
                          <p
                            className={
                              selected ? "font-semibold" : "font-normal"
                            }
                          >
                            {option.title}
                          </p>
                          {selected ? (
                            <span
                              className={active ? "text-white" : "text-purple"}
                            >
                              <CheckIcon
                                className="h-5 w-5"
                                aria-hidden="true"
                              />
                            </span>
                          ) : null}
                        </div>
                        <p
                          className={classNames(
                            active ? "text-purple-200" : "text-gray-500",
                            "mt-2"
                          )}
                        >
                          {option.description}
                        </p>
                      </div>
                    )}
                  </ListboxOption>
                ))}
              </ListboxOptions>
            </Transition>
          </div>
        </>
      )}
    </Listbox>
  );
};
