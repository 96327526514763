import ReactModal from "react-modal"
import { XCircleIcon, XMarkIcon } from "@heroicons/react/24/solid"
import { Dialog, DialogPanel, DialogTitle, Transition, TransitionChild } from "@headlessui/react"
import { Fragment } from "react"


type ModalProps = {
  variant?: React.ReactNode
  children: React.ReactNode
  isOpen: boolean
  size?: 'sm' | 'md' | 'lg' | 'xl' | 'screen'
  onRequestClose: () => void
} & React.HTMLAttributes<HTMLDivElement>

type Props = {
  children: React.ReactNode
} & React.HTMLAttributes<HTMLDivElement>

export const Container = ({children, className, ...rest}: Props) => {
  return (
    <div className={`mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 h-full ${className}`} {...rest}>
      <div className="max-w-full h-full">
        {children}
      </div>
    </div>
  )
}

const modalVariantColors = {
  default: 'gray-300',
  success: '',
  error: '',
  warning: ''
}

export const Modal = ({children, variant = modalVariantColors['default'], size = "md", title, isOpen, onRequestClose}: ModalProps) => {
  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={onRequestClose}>
          <TransitionChild
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black/25" />
          </TransitionChild>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center relative">
              <TransitionChild
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <DialogPanel className={`w-full max-w-${size} max-h-[calc(100vh-10rem)] transform overflow-x-hidden overflow-y-auto rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all`}>
                  {title ? (
                    <DialogTitle
                      as="h3"
                      className="text-lg font-medium leading-6 text-gray-900"
                    >
                      {title}
                    </DialogTitle>
                  ) : null}
                  <button className="absolute right-3 top-3">
                    <XMarkIcon className="w-6 h-6 text-gray-500" onClick={onRequestClose} />
                  </button>
                  <div className="mt-2">
                    {children}
                  </div>
                </DialogPanel>
              </TransitionChild>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  )
}